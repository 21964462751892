import { useState, useEffect } from "react";
import { Input, Button, Select, SelectItem } from "@nextui-org/react";
import { post } from "aws-amplify/api";
import { useTheme } from "next-themes";
import { useNavigate } from "react-router-dom";

export default function Coupons(props: { isAuthenticated: boolean }) {
  const navigate = useNavigate();

  useEffect(() => {
    props.isAuthenticated !== true && navigate("/");
  }, []);

  const { theme } = useTheme();
  const [couponType, setCouponType] = useState<string>("percent");
  const [couponRate, setCouponRate] = useState<number | "">("");
  const [couponToken, setCouponToken] = useState<string>("");
  const [duration, setDuration] = useState<string>("once");
  const [durationInMonths, setDurationInMonths] = useState<number | "">("");
  const [couponGenerating, setCouponGenerating] = useState<boolean>(false);

  interface Coupon {
    id: string;
    name?: string;
    valid: boolean;
    amount_off?: number;
    percent_off?: number;
    created: number;
    duration: string;
    duration_in_months?: number;
    times_redeemed: number;
    currency: string;
  }
  const [couponsList, setCouponsList] = useState<Coupon[]>([]);

  useEffect(() => {
    listCoupons();
  }, []);

  const listCoupons = async () => {
    try {
      const restOperation = post({
        apiName: "apie10d1ecd",
        path: "/list-coupons",
      });
      const response = await restOperation.response;
      if (response && response.body) {
        const responseBody = (await response.body.json()) as { data?: Coupon[] };
        if (responseBody && responseBody.data && Array.isArray(responseBody.data)) {
          setCouponsList(responseBody.data);
        } else {
          console.error("Invalid data format in response:", responseBody);
        }
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (e) {
      console.log("POST call failed: ", e);
    }
  };
  const getCouponBgColor = (valid: boolean, theme: string | undefined) => {
    if (valid) {
      return theme === "dark" ? "bg-grey-700" : "bg-grey-700";
    } else {
      return theme === "dark" ? "bg-grey-700" : "bg-rose-600";
    }
  };

  const handleGenerateCoupon = async () => {
    try {
      setCouponGenerating(true);
      const requestBody: any = {
        coupon_type: couponType,
        percent_off: couponType === "percent" ? couponRate : undefined,
        amount_off: couponType === "amount" ? couponRate : undefined,
        currency: "USD",
        duration,
        duration_in_months: duration === "repeating" ? durationInMonths : undefined,
      };

      const restOperation = post({
        apiName: "getcoupon",
        path: "/getcoupon",
        options: {
          body: requestBody,
        },
      });

      const response = await restOperation.response;
      if (response) {
        const responseBody = (await response.body.json()) as { coupon_id: string };
        if (responseBody && responseBody.coupon_id) {
          setCouponToken(responseBody.coupon_id);
          listCoupons();
        } else {
          console.error("Invalid response format:", responseBody);
        }
      } else {
        console.error("Invalid response format:", response);
      }
      setCouponGenerating(false);
    } catch (error) {
      setCouponGenerating(false);
      console.error("Error generating coupon:", error);
    }
  };

  return (
    <div className="flex flex-row justify-center">
      <div className="w-full max-w-md">
        <h1 className="text-3xl font-bold mb-6">Current Coupons</h1>
        <div className="border rounded-l-lg p-4 self-start overflow-y-auto max-h-[60vh] mr-14">
          {couponsList.map((coupon) => (
            <div
              key={coupon.id}
              className={`border rounded-md p-2 mb-4 mr-14 ${getCouponBgColor(
                coupon.valid,
                theme
              )}`}
            >
              {coupon.name && <h2 className="text-l font-bold">Name: {coupon.name}</h2>}
              <h2 className="text-l font-bold">ID: {coupon.id}</h2>
              {coupon.amount_off ? (
                <p className="">
                  <span className="underline"> Amount Off:</span> {coupon.amount_off / 100}
                </p>
              ) : (
                <p>
                  <span className="underline"> Percent Off:</span> {coupon.percent_off}%
                </p>
              )}
              <p>
                <span className="underline"> created:</span>
                {new Date(coupon.created * 1000).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
              </p>
              <p>
                <span className="underline"> Duration:</span> {coupon.duration}
              </p>
              {coupon.duration === "repeating" && (
                <p>
                  <span className="underline"> Duration in Months:</span>
                  {coupon.duration_in_months}
                </p>
              )}
              {coupon.currency && (
                <p>
                  <span className="underline"> Currency:</span> {coupon.currency}
                </p>
              )}
              <p>
                <span className="underline"> Times Redeemed:</span> {coupon.times_redeemed}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full max-w-md">
        <h1 className="text-3xl font-bold mb-6">Generate Coupons</h1>
        <Select
          label="Coupon Type"
          value={couponType}
          onChange={(e) => setCouponType(e.target.value)}
          className="mb-4"
          defaultSelectedKeys={["percent"]}
        >
          <SelectItem key="percent" value="percent">
            Percentage Off
          </SelectItem>
          <SelectItem key="amount" value="amount">
            Amount Off
          </SelectItem>
        </Select>
        <Input
          className="mb-4"
          type="number"
          label={couponType === "percent" ? "Enter percent off" : "Enter amount off"}
          value={couponRate?.toString()}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setCouponRate(isNaN(value) ? "" : value);
          }}
        />

        <Select
          label="Duration"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          className="mb-4"
        >
          <SelectItem key="once" value="once">
            Once
          </SelectItem>
          <SelectItem key="forever" value="forever">
            Forever
          </SelectItem>
          <SelectItem key="repeating" value="repeating">
            Repeating
          </SelectItem>
        </Select>
        {duration === "repeating" && (
          <Input
            className="mb-4"
            type="number"
            label="Enter Duration in Months"
            value={durationInMonths ? durationInMonths.toString() : ""}
            onChange={(e) => setDurationInMonths(parseInt(e.target.value))}
          />
        )}
        <Button isLoading={couponGenerating} onClick={handleGenerateCoupon}>
          Generate Coupon
        </Button>
        {couponToken && (
          <div className="mt-6">
            <h2 className="text-xl font-bold">Coupon Token:</h2>
            <p className="text-lg">{couponToken}</p>
          </div>
        )}
      </div>
    </div>
  );
}
