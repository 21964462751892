import { 
  getAllData,
  getAllDocumentsFromMainAndSubcollection,
} from '../lib/firestoreOperations';

const collectionName = "searches"

export const getAllSearches = async () => {
  return await getAllData(collectionName)
}

export const getAllSearchesAndSubDocuments = async (inputSearchId: string) => {
  return await getAllDocumentsFromMainAndSubcollection(collectionName, "input_search_id", inputSearchId, "patents")
}
