import { Document, Link, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { ReportData, SynopsisDetailItem, isFeatureDetailV2 } from "../types/reportTypes";

const styles = StyleSheet.create({
  listContainer: {
    padding: "20px",
    lineHeight: 1.3,
  },

  page: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 5,
    fontSize: 12,
    fontFamily: "Times-Roman",
    height: 2000,
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 25,
    borderBottom: "1px",
    width: 300,
    alignSelf: "center",
    fontFamily: "Times-Bold",
  },
  noveltyScore: {
    fontSize: 14,
    textAlign: "center",
    width: 160,
    alignSelf: "center",
    marginBottom: "10px",
  },
  headerContainer: {
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginHorizontal: 20,
    marginBottom: "20px",
  },

  list: {
    paddingHorizontal: "25px",
    paddingTop: "5px",
  },
  listHeader: {
    marginBottom: "5px",
    fontFamily: "Times-Bold",
    fontSize: "14px",
  },
  listItem: {
    padding: "5px",
  },
  tableTitle: {
    fontFamily: "Times-Bold",
    fontSize: "14px",
    marginVertical: "5px",
    alignSelf: "center",
    marginTop: "25px",
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#EEE",
  },
  header: {
    flexDirection: "row",
    backgroundColor: "#f0f0f0",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#EEE",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#EEE",
    padding: "5px",
  },
  abstractText: {
    textAlign: "left",
  },
  cell: {
    fontSize: 11,
    padding: 8,
    textAlign: "center",
  },
  headerCell: {
    fontSize: 12,
    padding: 4,
    textAlign: "center",
  },
  additionalDetailsFeature: {
    padding: "5px",
    marginBottom: "20px",
  },
  additionalDetailsBox: {
    marginBottom: "12px",
    marginHorizontal: "10px",
  },

  cell1: { width: "10%" },
  cell2: { width: "10%" },
  cell3: { width: "20%" },
  cell4: { width: "15%" },
  cell5: { width: "35%" },
  cell6: { width: "10%" },

  cell1a: { width: "15%" },
  cell2a: { width: "30%" },
  cell3a: { width: "30%" },
  cell4a: { width: "25%" },
});

const Report = ({ reportData }: { reportData: ReportData }) => {
  const enforceHttps = (url: string) => {
    if (url.startsWith("https://")) return url;
    return url.replace(/^http:\/\//i, "https://");
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        {/* HEADING */}
        <View>
          <Text style={styles.title}>intelliPat Novelty Report</Text>
          <View style={{ backgroundColor: "#f0f0f0", paddingTop: "15px", borderRadius: "10px" }}>
            <Text style={styles.noveltyScore}>Novelty Score: {reportData?.noveltyScore}</Text>
            <View style={styles.headerContainer}>
              <Text>Feature Coverage: {reportData.featureCoverage.join(", ")}</Text>
              {reportData.secondaryFeatureCoverage && (
                <Text>Secondary Coverage: {reportData.secondaryFeatureCoverage.join(", ")}</Text>
              )}
            </View>
          </View>
        </View>

        {/* FEATURE LIST */}
        <View style={styles.listContainer}>
          <Text style={styles.listHeader}>Primary Features</Text>
          {reportData.primaryFeatures.map((pFeature, index) => (
            <Text key={index} style={styles.listItem}>
              {`${index + 1}) ${pFeature}`}
            </Text>
          ))}
        </View>
        {reportData.secondaryFeatures && (
          <View style={styles.listContainer}>
            <Text style={styles.listHeader}>Secondary Features</Text>
            {reportData.secondaryFeatures.map((sFeature, index) => (
              <Text key={index} style={styles.listItem}>
                {`${index + 1}) ${sFeature}`}
              </Text>
            ))}
          </View>
        )}

        {/* PRIOR ART TABLE DATA */}
        <View break>
          <Text style={styles.tableTitle}>Prior Art Table</Text>
          <View style={styles.table} fixed>
            {/* Table Header */}
            <View style={[styles.row, styles.header]}>
              <Text style={[styles.cell, styles.headerCell, styles.cell1]}>Coverage</Text>
              <Text style={[styles.cell, styles.headerCell, styles.cell2]}>Patent #</Text>
              <Text style={[styles.cell, styles.headerCell, styles.cell3]}>Title</Text>
              <Text style={[styles.cell, styles.headerCell, styles.cell4]}>Inventor(s)</Text>
              <Text style={[styles.cell, styles.headerCell, styles.cell5]}>Abstract</Text>
              <Text style={[styles.cell, styles.headerCell, styles.cell6]}>Match</Text>
            </View>
          </View>

          {/* Table Rows */}
          {reportData.priorArtTable.map((priorArtEntry, index) => (
            <View key={index} style={styles.row} wrap={false}>
              <Text style={[styles.cell, styles.cell1]}>{priorArtEntry.coverage}</Text>
              <Text style={[styles.cell, styles.cell2]}>
                {priorArtEntry.patentLink ? (
                  <Link src={enforceHttps(priorArtEntry.patentLink)}>
                    {priorArtEntry.patentNumber}
                  </Link>
                ) : (
                  priorArtEntry.patentNumber
                )}
              </Text>
              <Text style={[styles.cell, styles.cell3]}>{priorArtEntry.title}</Text>
              <Text style={[styles.cell, styles.cell4]}>{priorArtEntry.inventors}</Text>
              <Text style={[styles.cell, styles.cell5, styles.abstractText]}>
                {priorArtEntry.abstract}
              </Text>
              <Text style={[styles.cell, styles.cell6]}>{priorArtEntry.match}</Text>
            </View>
          ))}
        </View>

        {/* ADDITIONAL DETAILS */}
        <View break>
          <Text style={styles.tableTitle}>Detail On Prior Art</Text>
          {reportData.priorArtTable.map((priorArtEntry, index) => (
            <View key={index}>
              <View style={styles.table} wrap={false}>
                <View style={[styles.row, styles.header]}>
                  <Text style={[styles.cell, styles.headerCell, styles.cell1]}>Coverage</Text>
                  <Text style={[styles.cell, styles.headerCell, styles.cell2]}>Patent #</Text>
                  <Text style={[styles.cell, styles.headerCell, styles.cell3]}>Title</Text>
                  <Text style={[styles.cell, styles.headerCell, styles.cell4]}>Inventor(s)</Text>
                  <Text style={[styles.cell, styles.headerCell, styles.cell5]}>Abstract</Text>
                  <Text style={[styles.cell, styles.headerCell, styles.cell6]}>Match</Text>
                </View>

                <View key={index} style={styles.row}>
                  <Text style={[styles.cell, styles.cell1]}>{priorArtEntry.coverage}</Text>
                  <Text style={[styles.cell, styles.cell2]}>
                    {priorArtEntry.patentLink ? (
                      <Link src={enforceHttps(priorArtEntry.patentLink)}>
                        {priorArtEntry.patentNumber}
                      </Link>
                    ) : (
                      priorArtEntry.patentNumber
                    )}
                  </Text>
                  <Text style={[styles.cell, styles.cell3]}>{priorArtEntry.title}</Text>
                  <Text style={[styles.cell, styles.cell4]}>{priorArtEntry.inventors}</Text>
                  <Text style={[styles.cell, styles.cell5, styles.abstractText]}>
                    {priorArtEntry.abstract}
                  </Text>
                  <Text style={[styles.cell, styles.cell6]}>{priorArtEntry.match}</Text>
                </View>
              </View>
              <View style={styles.listContainer}>
                {priorArtEntry.detail.map((feature, index) => (
                  <View key={index} style={styles.additionalDetailsFeature}>
                    <Text style={{ fontFamily: "Times-Bold", marginBottom: "5px" }}>
                      {feature.feature}
                    </Text>
                    {feature.content.map((content, index) => (
                      <View key={index} style={styles.additionalDetailsBox}>
                        <Text>{content.detailForFeature}</Text>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>

        {/* FEATURE SYNOPSIS */}
        <View break>
          <Text style={styles.tableTitle}>Feature Synopsis</Text>
          {reportData.primaryFeatureTable.map((pFeature, index) => (
            <View key={index}>
              <View style={styles.table} wrap={false}>
                <View style={[styles.row, styles.header]}>
                  <Text style={[styles.cell, styles.headerCell, styles.cell1a]}>Coverage</Text>
                  <Text style={[styles.cell, styles.headerCell, styles.cell2a]}>Title</Text>
                  <Text style={[styles.cell, styles.headerCell, styles.cell3a]}>Inventor(s)</Text>
                  <Text style={[styles.cell, styles.headerCell, styles.cell4a]}>Patent Number</Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.cell, styles.cell1a]}>{pFeature.coverage}</Text>
                  <Text style={[styles.cell, styles.cell2a]}>{pFeature.title}</Text>
                  <Text style={[styles.cell, styles.cell3a]}>{pFeature.inventors.join(", ")}</Text>
                  <Text style={[styles.cell, styles.cell4a]}>
                    {pFeature.patentLink ? (
                      <Link src={enforceHttps(pFeature.patentLink)}>{pFeature.patentNumber}</Link>
                    ) : (
                      pFeature.patentNumber
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.listContainer}>
                {pFeature.detail.map((detail, index) =>
                  isFeatureDetailV2(pFeature) ? (
                    <View key={index} style={styles.additionalDetailsBox}>
                      <Text>{(detail as SynopsisDetailItem).content}</Text>
                    </View>
                  ) : (
                    <View key={index} style={styles.additionalDetailsBox}>
                      <Text>{detail as string}</Text>
                    </View>
                  )
                )}
              </View>
            </View>
          ))}
          {reportData.secondaryFeatureTable && (
            <View break>
              <Text style={styles.tableTitle}>Secondary Feature Synopsis</Text>
              {reportData.secondaryFeatureTable.map((sFeature, index) => (
                <View key={index}>
                  <View style={styles.table} wrap={false}>
                    <View style={[styles.row, styles.header]}>
                      <Text style={[styles.cell, styles.headerCell, styles.cell1a]}>Coverage</Text>
                      <Text style={[styles.cell, styles.headerCell, styles.cell2a]}>Title</Text>
                      <Text style={[styles.cell, styles.headerCell, styles.cell3a]}>
                        Inventor(s)
                      </Text>
                      <Text style={[styles.cell, styles.headerCell, styles.cell4a]}>
                        Patent Number
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={[styles.cell, styles.cell1a]}>{sFeature.coverage}</Text>
                      <Text style={[styles.cell, styles.cell2a]}>{sFeature.title}</Text>
                      <Text style={[styles.cell, styles.cell3a]}>
                        {sFeature.inventors.join(", ")}
                      </Text>
                      <Text style={[styles.cell, styles.cell4a]}>
                        {sFeature.patentLink ? (
                          <Link src={enforceHttps(sFeature.patentLink)}>
                            {sFeature.patentNumber}
                          </Link>
                        ) : (
                          sFeature.patentNumber
                        )}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.listContainer}>
                    {sFeature.detail.map((detail, index) =>
                      isFeatureDetailV2(sFeature) ? (
                        <View key={index} style={styles.additionalDetailsBox}>
                          <Text>{(detail as SynopsisDetailItem).content}</Text>
                        </View>
                      ) : (
                        <View key={index} style={styles.additionalDetailsBox}>
                          <Text>{detail as string}</Text>
                        </View>
                      )
                    )}
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default Report;
