import { motion } from "framer-motion";
import {
  AdditionalFeaturesProps,
  SynopsisDetailItem,
  isFeatureDetailV2,
} from "../types/reportTypes";

export default function AdditionalDetails({ featureDetail }: AdditionalFeaturesProps) {
  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3, ease: "linear" }}
    >
      <div className="p-3 border-b">
        <h3 className="text-lg">
          Additional details about feature number{" "}
          {featureDetail.patentLink ? (
            <a
              href={featureDetail.patentLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600"
            >
              {featureDetail.patentNumber}
            </a>
          ) : (
            featureDetail.patentNumber
          )}
          :
        </h3>

        <div>
          <h4 className="pt-5 pb-3 font-bold">{featureDetail.feature}</h4>

          {featureDetail.detail.map((item, contentIndex) => {
            if (isFeatureDetailV2(featureDetail)) {
              const detailItem = item as SynopsisDetailItem;
              return <p key={contentIndex}>{detailItem.content}</p>;
            } else {
              return <p key={contentIndex}>{item as string}</p>;
            }
          })}
        </div>
      </div>
    </motion.div>
  );
}
