import Head from "next/head";
import { useState, useEffect } from "react";
import SearchesResultsTable from "../../../components/searchedResultsTable";
import { Button, Link } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

export default function Searches(props: { userIdentity: any; isAuthenticated: boolean }) {
  const navigate = useNavigate();

  const [user, setUser] = useState(props.userIdentity) as any;
  const [uuid, setUuid] = useState<string>("");

  useEffect(() => {
    props.isAuthenticated !== true && navigate("/");
  }, []);

  return (
    <>
      <Head>
        <title>Reports</title>
      </Head>
      {user && <SearchesResultsTable key={uuid} currentAuthUser={user} />}
      <div className="flex justify-center my-6 p-2">
        <Link isExternal href="https://form.asana.com/?k=CQN2OxlJFQzll1P_jp_iuQ&d=4641128882264">
          <Button
            className="text-white dark:bg-[#d1d5db] dark:text-black bg-[#1e293b]"
            radius="lg"
            size="lg"
          >
            We'd Love To Get Feedback From You!
          </Button>
        </Link>
      </div>
    </>
  );
}
