/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Textarea,
  Select,
  SelectItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "@nextui-org/react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createInputSearch,
  getAllSavedInventions,
  saveUserInvention,
  updateUserInvention,
} from "../../services/inputSearchService";
import { getCurrentUser, fetchAuthSession, AuthUser } from "aws-amplify/auth";
import { IoIosSave } from "react-icons/io";
import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs";
import StripeCheckoutForm from "../checkout/stripeCheckoutForm";
import { Stars, CloseIcon, EditIcon, Star } from "../../components/icons";
import { post } from "aws-amplify/api";
import { Invention, AWSCredentials, PrimaryItemType } from "../../types/searchFormTypes";
import LoadInvention from "../../components/loadInvention";
import LoadInventionModal from "../../components/loadInventionModal";

export default function Search() {
  const navigate = useNavigate();

  const [user, setUser] = useState(null) as any;
  const [userIdentity, setUserIdentity] = useState<any | null>(null);

  const minWords = 30;
  const [gen_features_clicked, setGenFeaturesClicked] = useState<boolean>(false);

  const listTechnologyArea = [
    "Biotechnology and Pharmaceuticals",
    "Chemistry and Materials",
    "Computing and Software",
    "Electronics and Semiconductors",
    "Energy and Environmental Technology",
    "Mechanical Engineering and Machinery",
    "Telecommunications",
    "Automotive and Transport",
    "Aerospace and Aviation",
    "Agriculture and Food Technology",
    "Medical Devices and Health Technologies",
    "Optics and Photonics",
    "Robotics and Automation",
    "Consumer Goods and Apparel",
    "Construction and Civil Engineering",
    "Nanotechnology and Microfabrication",
  ];
  const listInventionType = [
    "Device/Apparatus",
    "System/Assembly",
    "Method/Process (including business methods)",
    "Composition of Matter",
    "Method or Article of Manufacture",
    "Software",
  ];
  const location = useLocation();
  const [titleMatter, setTitleMatter] = useState<string>(
    location.state ? location.state.titleMatter ?? "" : ""
  );
  const [technologyArea, setTechnologyArea] = useState<string>(
    location.state ? location.state.technology_area ?? "" : ""
  );
  const [inventionType, setInventionType] = useState<string>(
    location.state ? location.state.invention_type ?? "" : ""
  );
  const [bigTextBox, setBigTextBox] = useState<string>(
    location.state ? location.state.bigTextBox ?? "" : ""
  );
  const [bigTextWordsRemaining, setBigTextWordsRemaining] = useState<number>(0);
  const [primaryInput, setPrimaryInput] = useState<string>("");
  const [secondaryInput, setSecondaryInput] = useState<string>("");
  const [primary, setPrimary] = useState<string[]>(
    location.state ? location.state.primary.map((item: any[]) => item[0]) ?? [] : []
  );
  const [primaryExt, setPrimaryExt] = useState<PrimaryItemType[]>(
    location.state ? [...location.state.primary] : []
  );
  const [secondary, setSecondary] = useState<string[]>(
    location.state ? location.state.secondary.map((item: any[]) => item) ?? [] : []
  );
  const editTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const titleMatterRef = useRef<HTMLTextAreaElement>(null);
  const [editPrimaryIndex, setEditPrimaryIndex] = useState<number>(-1);
  const [editedPrimaryValue, setEditedPrimaryValue] = useState<string>("");
  const [editPrimaryValidation, setEditPrimaryValidation] = useState<boolean[]>([]);
  const [editSecondaryIndex, setEditSecondaryIndex] = useState<number>(-1);
  const [editedSecondaryValue, setEditedSecondaryValue] = useState<string>("");
  const [editSecondaryValidation, setEditSecondaryValidation] = useState<boolean[]>([]);
  const [email, setEmail] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [inputValidation, setInputValidation] = useState({
    technologyArea: false,
    inventionType: false,
    titleMatter: false,
    bigTextBox: false,
    primary: false,
    primaryTextLeft: false,
    secondaryTextLeft: false,
    email: false,
  });

  const [isResultsAPILoading, setIsResultsAPILoading] = useState<boolean>(false);
  const [featureGenerating, setFeatureGenerating] = useState<string>("");
  const [isSavingInvention, setIsSavingInvention] = useState<boolean>(false);
  const [inventions, setInventions] = useState<Invention[]>([]);
  const [selectedInventionId, setSelectedInventionId] = useState<string>("");
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [modalInput, setModalInput] = useState<{ id: string; input: Invention }>();

  const stringWithoutSpecialCharacters = (originalString: string) =>
    originalString.replace(/\\'/g, "'");

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validateBigText = (bigText: string) => {
    if (bigText === "" || bigTextWordsRemaining < 0 || bigTextWordsRemaining > 800) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    const wordsCount = bigTextBox.trim().split(/\s+/).length;
    bigTextBox.trim() !== "" ? setBigTextWordsRemaining(wordsCount) : setBigTextWordsRemaining(0);
  }, [bigTextBox]);

  const sendSQSMessage = async (data: any) => {
    const { credentials } = await fetchAuthSession();
    const { accessKeyId, sessionToken, secretAccessKey } = credentials as AWSCredentials;
    const sqsClient = new SQSClient({
      region: "us-east-2",
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        sessionToken: sessionToken,
      },
    });

    try {
      const params = {
        QueueUrl: `${import.meta.env.VITE_QUEUE_URL}`,
        MessageBody: JSON.stringify(data),
        MessageGroupId: uuidv4(),
        MessageDeduplicationId: uuidv4(),
      };

      const command = new SendMessageCommand(params);
      const result = await sqsClient.send(command);

      console.log("Message sent successfully:", result.MessageId);

      return result;
    } catch (error) {
      console.error("Error sending message to SQS:", error);
    }
  };

  const saveInvention = async () => {
    const isTitleMatterValid = titleMatter !== "";
    setInputValidation((prevState) => ({
      ...prevState,
      titleMatter: !isTitleMatterValid,
    }));
    if (isTitleMatterValid) {
      await saveInventionData({
        user_id: user.userId,
        user_email: userIdentity.email,
        titleMatter: titleMatter,
        metadata: JSON.stringify({
          technology_area: stringWithoutSpecialCharacters(technologyArea),
          invention_type: stringWithoutSpecialCharacters(inventionType),
          titleMatter: titleMatter,
          bigTextBox: stringWithoutSpecialCharacters(bigTextBox),
          primary: primaryExt,
          secondary: secondary,
          email: userIdentity.email,
        }),
      });
    } else {
      setTimeout(() => {
        if (titleMatterRef.current) {
          titleMatterRef.current.focus();
        }
      }, 0);
    }
    await loadInventions(user);
  };

  const handleConfirmOverwrite = async () => {
    try {
      await updateUserInvention({ docId: modalInput?.id, data: modalInput?.input });
      setIsSaveModalOpen(false);
    } catch (error) {
      console.error("Failed to update invention:", error);
    } finally {
      setIsSavingInvention(false);
    }
  };

  const saveInventionData = async (input: any) => {
    setIsSavingInvention(true);
    const titleMatters = inventions?.map((invention) => invention.titleMatter);
    const titleExists = titleMatters && titleMatters.includes(input.titleMatter);
    const existingInvention = titleExists
      ? inventions?.find((invention) => invention.titleMatter === input.titleMatter)
      : null;
    try {
      if (titleExists && existingInvention) {
        const { id: existingId, ...existingWithoutId } = existingInvention;
        const sameValues = JSON.stringify(existingWithoutId) === input.metadata;
        if (sameValues === false) {
          setModalInput({ id: existingInvention.id, input });
          setIsSaveModalOpen(true);
        }
      } else if (!titleExists) {
        await saveUserInvention({ data: input });
      }
    } catch (error) {
      console.error("Error in saveInventionData:", error);
      throw error;
    } finally {
      setIsSavingInvention(false);
    }
  };

  const fetchData = async (input: any) => {
    setIsResultsAPILoading(true);
    try {
      input.status = "pending";
      const inputSearch = await createInputSearch({ data: input });
      if (inputSearch) {
        await sendSQSMessage({ input_search_id: inputSearch.id });
      } else {
        throw new Error("inputSearch.id is undefined");
      }
    } catch (error) {
      console.error("Error while trying to process the search:", error);
    } finally {
      setBigTextBox("");
      setPrimaryInput("");
      setSecondaryInput("");
      setPrimary([]);
      setSecondary([]);
      setIsFormValid(false);
      setIsResultsAPILoading(false);
      handleRedirectReport();
    }
  };

  const isSingleRecord = (item: string, type: string) => {
    if (item.length === 0) return false;

    selectedItem(stringWithoutSpecialCharacters(item), type);

    return true;
  };

  const handleEditItem = (index: number, editedValue: string, type: string) => {
    const isDuplicate = primary.some((item, idx) => idx !== index && item === editedValue);
    if (type === "primary") {
      if (isDuplicate) {
        setEditPrimaryValidation((prevValidation) => {
          const updatedValidation = [...prevValidation];
          updatedValidation[index] = true;
          return updatedValidation;
        });
      } else {
        setPrimary((prevPrimary) =>
          prevPrimary.map((item, idx) => (idx === index ? editedValue : item))
        );
        setPrimaryExt((prevPrimary) =>
          prevPrimary.map((item, idx) => (idx === index ? [editedValue, item[1]] : item))
        );
        setEditPrimaryIndex(-1);
        setEditPrimaryValidation([]);
      }
    }
    if (type === "secondary") {
      if (isDuplicate) {
        setEditSecondaryValidation((prevValidation) => {
          const updatedValidation = [...prevValidation];
          updatedValidation[index] = true;
          return updatedValidation;
        });
      } else {
        setSecondary((prevSecondary) =>
          prevSecondary.map((item, idx) => (idx === index ? editedValue : item))
        );
        setEditSecondaryIndex(-1);
        setEditSecondaryValidation([]);
      }
    }
  };

  const selectedItem = (item: string, type: string) => {
    if (type === "primary") {
      handleItemClose(item, setPrimary);
      setPrimary((prevPrimary) => [...prevPrimary, item]);
      setPrimaryInput("");
      handleItemExtClose(item, setPrimaryExt);
      setPrimaryExt((prevPrimary) => [...prevPrimary, [item, 0]]);
    }
    if (type === "secondary") {
      handleItemClose(item, setSecondary);
      setSecondary((prevSecondary) => [...prevSecondary, item]);
      setSecondaryInput("");
    }
  };

  const handleRedirectReport = () => {
    navigate("/search/searches");
  };

  const onSuccess = () =>
    fetchData({
      user_id: user.userId,
      user_email: userIdentity.email,
      titleMatter: titleMatter,
      metadata: JSON.stringify({
        technology_area: stringWithoutSpecialCharacters(technologyArea),
        invention_type: stringWithoutSpecialCharacters(inventionType),
        titleMatter: titleMatter,
        bigTextBox: stringWithoutSpecialCharacters(bigTextBox),
        primary: primaryExt,
        secondary: secondary,
        opt: {},
        email: userIdentity.email,
      }),
    });
  const handlePrimaryApi = () => isSingleRecord(primaryInput, "primary");
  const handleSecondaryApi = () => isSingleRecord(secondaryInput, "secondary");
  const handleSelectedOptionTechnologyArea = (option: string) => setTechnologyArea(option);
  const handleSelectedOptionInventionType = (option: string) => setInventionType(option);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleItemClose = (itemToRemove: string, setter: Function) => {
    setter((prevItems: string[]) => prevItems.filter((item) => item !== itemToRemove));
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleItemExtClose = (itemToRemove: string, setter: Function) => {
    setter((prevItems: PrimaryItemType[]) => prevItems.filter((item) => item[0] !== itemToRemove));
  };

  const handleIsUniqueButtonClick = (index: number) => {
    setPrimaryExt((prevPrimary) => {
      const updatedPrimary = [...prevPrimary];
      updatedPrimary[index][1] = updatedPrimary[index][1] ? 0 : 1;
      return updatedPrimary;
    });
  };

  useEffect(() => {
    getUserData();
    if (selectedInventionId) {
      const invention =
        inventions &&
        (inventions.find(
          (invention: { id: string }) => invention.id === selectedInventionId
        ) as Invention);
      if (invention) {
        setTechnologyArea(invention.technology_area ?? "");
        setInventionType(invention.invention_type ?? "");
        setTitleMatter(invention.titleMatter ?? "");
        setBigTextBox(invention.bigTextBox ?? "");
        setPrimary(invention.primary.map((item) => item[0]) ?? []);
        setPrimaryExt(invention.primary ?? []);
        setSecondary(invention.secondary ?? []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventionId]);

  const getUserData = async (): Promise<void> => {
    try {
      const currentUser = await getCurrentUser();
      const { tokens } = await fetchAuthSession();
      const userIdentity = tokens?.idToken?.payload;
      setUser(currentUser);
      setUserIdentity(userIdentity);
      if (userIdentity && userIdentity.email) {
        setEmail(userIdentity.email.toString());
      }
      loadInventions(currentUser);
    } catch (error) {
      console.log("Not signed in");
    }
  };

  const loadInventions = async (currentUser: AuthUser) => {
    const inputSearchData = await getAllSavedInventions(currentUser.userId);
    const inventions = inputSearchData.map((item: any) => {
      const metadata = JSON.parse(item.data.metadata);
      metadata.id = item.id;
      return metadata;
    });
    if (inventions) {
      setInventions(inventions);
    }
  };

  const generateFeatures = (primaryfeatNeeded: boolean, secondaryfeatNeeded: boolean) => {
    const isEmailValid = validateEmail(email);
    const isBigTextBoxValid = validateBigText(bigTextBox);

    setInputValidation((prevState) => ({
      ...prevState,
      bigTextBox: !isBigTextBoxValid,
      email: !isEmailValid,
    }));
    if (isEmailValid && isBigTextBoxValid) {
      if (primaryfeatNeeded && secondaryfeatNeeded) {
        setFeatureGenerating("both");
        autoGenerateFeatures(primaryfeatNeeded, secondaryfeatNeeded);
      } else if (primaryfeatNeeded) {
        setFeatureGenerating("primary");
        autoGenerateFeatures(primaryfeatNeeded, secondaryfeatNeeded);
      } else if (secondaryfeatNeeded) {
        setFeatureGenerating("secondary");
        autoGenerateFeatures(primaryfeatNeeded, secondaryfeatNeeded);
      }
      return true;
    } else return false;
  };

  interface GeneratedFeatures {
    message?: {
      primary?: any[][][];
      secondary?: any[];
    };
  }
  const autoGenerateFeatures = async (primaryfeatNeeded: boolean, secondaryfeatNeeded: boolean) => {
    try {
      const restOperation = post({
        apiName: "maingptapi",
        path: "/gen-features",
        options: {
          body: {
            patentText: bigTextBox,
            primaryfeatNeeded: primaryfeatNeeded,
            secondaryfeatNeeded: secondaryfeatNeeded,
            userId: user.userId,
            userEmail: userIdentity.email,
          },
        },
      });
      const { body } = await restOperation.response;
      const responseData = (await body.json()) as GeneratedFeatures;
      if (responseData && responseData.message) {
        const generatedFeatures = JSON.parse(responseData.message as string);
        if (generatedFeatures.items) {
          const primaryFeatures = generatedFeatures.items.map((item: string) =>
            item.replace(/^- |[;,.]$/g, "")
          );
          const primaryFeaturesExt = generatedFeatures.items.map((item: string) => [
            item.replace(/^- |[;,.]$/g, ""),
            1,
          ]);
          primaryFeatures.forEach((element: string) => {
            handleItemClose(element, setPrimary);
          });
          setPrimary((oldArray) => [...primaryFeatures, ...oldArray]);
          primaryFeaturesExt.forEach((element: string) => {
            handleItemClose(element, setPrimaryExt);
          });
          setPrimaryExt((oldArray) => [...primaryFeaturesExt, ...oldArray]);
        }
      }
      setFeatureGenerating("");
    } catch (e) {
      console.log("Auto Generating Features Failed: ", e);
      setFeatureGenerating("");
    }
  };

  return (
    <section>
      <Modal
        backdrop="blur"
        isOpen={isLoginModalOpen}
        onClose={() => {
          setIsLoginModalOpen(false);
        }}
      >
        <ModalContent>
          <>
            <ModalHeader className="flex flex-col gap-1">Log in To Continue!</ModalHeader>
            <ModalFooter>
              <Button
                color="default"
                variant="flat"
                onPress={() => {
                  setIsLoginModalOpen(false);
                }}
              >
                Close
              </Button>
              <Button
                className="text-base dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
                variant="flat"
                onPress={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
      {currentPage === 1 && (
        <div>
          <div className="w-full grid grid-cols-12 gap-5 items-center">
            <Textarea
              isRequired
              classNames={{
                label: "text-base",
                input: "text-base",
              }}
              ref={titleMatterRef}
              minRows={1}
              className="col-span-4"
              label="Title / Matter Number"
              placeholder="Give a Title / Matter number for your invention"
              value={titleMatter}
              onChange={(e) => setTitleMatter(e.target.value)}
              autoFocus
              isInvalid={inputValidation.titleMatter}
              errorMessage={
                inputValidation.titleMatter ? (
                  <div className="text-sm">This field is required</div>
                ) : (
                  ""
                )
              }
            />
            <Select
              classNames={{
                label: "text-base",
                value: "text-base",
              }}
              size="lg"
              isRequired
              label="Technology Area"
              placeholder="Select Technology Area"
              scrollShadowProps={{ isEnabled: false }}
              selectedKeys={technologyArea !== "" ? [technologyArea] : []}
              onChange={(value) => handleSelectedOptionTechnologyArea(value.target.value)}
              className="col-span-4"
              isInvalid={inputValidation.technologyArea}
              errorMessage={
                inputValidation.technologyArea ? (
                  <div className="text-sm">This field is required</div>
                ) : (
                  ""
                )
              }
            >
              {listTechnologyArea.map((option) => (
                <SelectItem
                  classNames={{
                    title: "text-base",
                  }}
                  key={option}
                  textValue={option}
                >
                  {option}
                </SelectItem>
              ))}
            </Select>
            <Select
              classNames={{
                label: "text-base",
                value: "text-base",
              }}
              size="lg"
              isRequired
              label="Invention Type"
              placeholder="Select Invention Type"
              scrollShadowProps={{ isEnabled: false }}
              selectedKeys={inventionType !== "" ? [inventionType] : []}
              onChange={(value) => handleSelectedOptionInventionType(value.target.value)}
              className="col-span-4"
              isInvalid={inputValidation.inventionType}
              errorMessage={
                inputValidation.inventionType ? (
                  <div className="text-sm">This field is required</div>
                ) : (
                  ""
                )
              }
            >
              {listInventionType.map((option) => (
                <SelectItem
                  classNames={{
                    title: "text-base",
                  }}
                  className="w-full"
                  key={option}
                  textValue={option}
                >
                  {option}
                </SelectItem>
              ))}
            </Select>
            <div className="col-span-12">
              <Textarea
                classNames={{
                  label: "text-base",
                  input: "text-base",
                  errorMessage: "text-sm",
                }}
                isRequired
                label="Invention Description"
                value={bigTextBox}
                minRows={6}
                onValueChange={setBigTextBox}
                placeholder="Describe the invention in general words, why is the invention needed, what the invention is and how the invention helps."
                isInvalid={inputValidation.bigTextBox}
                errorMessage={
                  bigTextWordsRemaining > 800
                    ? "Word Limit exceeded!"
                    : inputValidation.bigTextBox
                    ? "This field is required"
                    : ""
                }
              />
              <div className="flex justify-end items-center mt-1">
                <div className="text-foreground-500 mr-5">{`Suggested word count: ${bigTextWordsRemaining} / 800`}</div>
              </div>
            </div>
            <div className="col-span-12">
              <div className="w-full grid grid-cols-12 gap-5 items-center">
                <Textarea
                  classNames={{
                    label: "text-base",
                    input: "text-base",
                  }}
                  isRequired
                  className="col-span-11"
                  minRows={1}
                  label="Primary feature"
                  value={primaryInput}
                  onChange={(e) => setPrimaryInput(e.target.value)}
                  placeholder="The primary features are a list of the essential parts or elements that make up your invention, without which the invention would not function or achieve its intended purpose. Avoid unnecessary details and limitations. Try using functional language. Example: If you are claiming a mechanical device, list its crucial parts. The 'primary' features may be considered analogous to elements of an independent patent claim. 
Put brackets around the most important term/phrases."
                  isInvalid={inputValidation.primary || inputValidation.primaryTextLeft}
                  errorMessage={
                    inputValidation.primary ? (
                      <div className="text-sm">This field is required</div>
                    ) : inputValidation.primaryTextLeft ? (
                      <div className="text-sm">
                        Please add the primary feature or clear the input
                      </div>
                    ) : (
                      ""
                    )
                  }
                />
                <Button className="col-span-1" onClick={handlePrimaryApi}>
                  +
                </Button>
                {primary.map((item, index) => (
                  <div
                    key={index}
                    className="col-span-12 w-full grid grid-cols-12 gap-5 items-center"
                  >
                    <div className="col-span-1"></div>
                    {editPrimaryIndex === index ? (
                      <Textarea
                        ref={editTextAreaRef}
                        key={`textarea-${index}`}
                        className="col-span-5"
                        minRows={1}
                        value={editedPrimaryValue}
                        onChange={(e) => setEditedPrimaryValue(e.target.value)}
                        isInvalid={editPrimaryValidation[index]}
                        classNames={{
                          input: "text-base",
                        }}
                      />
                    ) : (
                      <div className="col-span-5">
                        <Textarea
                          key={`readonly-${index}`}
                          readOnly
                          minRows={1}
                          value={item}
                          classNames={{
                            input: "text-base",
                          }}
                        />
                      </div>
                    )}
                    <div className="col-span-2 flex">
                      {editPrimaryIndex === index ? (
                        <Button
                          key={`save-button-${index}`}
                          size="sm"
                          isIconOnly
                          className="dark:bg-[#3F3F46] bg-[#e5e7eb]"
                          endContent={<IoIosSave size={20} />}
                          variant="bordered"
                          onClick={() => {
                            handleEditItem(index, editedPrimaryValue, "primary");
                          }}
                        ></Button>
                      ) : (
                        <Button
                          key={`edit-button-${index}`}
                          size="sm"
                          isIconOnly
                          color="default"
                          variant="bordered"
                          onClick={() => {
                            setEditPrimaryIndex(index);
                            setEditedPrimaryValue(item);
                            setTimeout(() => {
                              if (editTextAreaRef.current) {
                                editTextAreaRef.current.focus();
                              }
                            }, 0);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      )}
                      <Button
                        key={`close-button-${index}`}
                        className="ml-2 dark:bg-[#3F3F46] bg-[#e5e7eb]"
                        size="sm"
                        isIconOnly
                        variant="bordered"
                        onClick={() => {
                          if (editPrimaryIndex === index) {
                            setEditPrimaryIndex(-1);
                          }
                          handleItemClose(item, setPrimary);
                          handleItemExtClose(item, setPrimaryExt);
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-12">
              <div className="w-full grid grid-cols-12 gap-5 items-center">
                <Textarea
                  classNames={{
                    label: "text-base",
                    input: "text-base",
                  }}
                  minRows={1}
                  label="Secondary feature (Optional)"
                  value={secondaryInput}
                  onChange={(e) => setSecondaryInput(e.target.value)}
                  placeholder="'Secondary' features are optional features that are not essential to the invention but could enhance it (e.g., provide 'add-ons' or 'extras' to the invention). 'Secondary' features may be considered analogous to dependent patent claims."
                  className="col-span-11"
                  isInvalid={inputValidation.secondaryTextLeft}
                  errorMessage={
                    inputValidation.secondaryTextLeft ? (
                      <div className="text-sm">
                        Please add the secondary feature or clear the input
                      </div>
                    ) : (
                      ""
                    )
                  }
                />
                <Button className="col-span-1" onClick={handleSecondaryApi}>
                  +
                </Button>
                {secondary.map((item, index) => (
                  <div
                    key={index}
                    className="col-span-12 w-full grid grid-cols-12 gap-5 items-center"
                  >
                    <div className="col-span-1"></div>
                    {editSecondaryIndex === index ? (
                      <Textarea
                        ref={editTextAreaRef}
                        key={`textarea-${index}`}
                        className="col-span-5"
                        minRows={1}
                        value={editedSecondaryValue}
                        onChange={(e) => setEditedSecondaryValue(e.target.value)}
                        isInvalid={editSecondaryValidation[index]}
                        classNames={{
                          input: "text-base",
                        }}
                      />
                    ) : (
                      <Textarea
                        className="col-span-5"
                        key={`readonly-${index}`}
                        readOnly
                        minRows={1}
                        value={item}
                        classNames={{
                          input: "text-base",
                        }}
                      />
                    )}
                    <div className="col-span-2 flex">
                      {editSecondaryIndex === index ? (
                        <Button
                          key={`save-button-${index}`}
                          size="sm"
                          className="dark:bg-[#3F3F46] bg-[#e5e7eb]"
                          isIconOnly
                          endContent={<IoIosSave size={20} />}
                          variant="bordered"
                          onClick={() => {
                            handleEditItem(index, editedSecondaryValue, "secondary");
                          }}
                        ></Button>
                      ) : (
                        <Button
                          key={`edit-button-${index}`}
                          size="sm"
                          isIconOnly
                          color="default"
                          variant="bordered"
                          onClick={() => {
                            setEditSecondaryIndex(index);
                            setEditedSecondaryValue(item);
                            setTimeout(() => {
                              if (editTextAreaRef.current) {
                                editTextAreaRef.current.focus();
                              }
                            }, 0);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      )}
                      <Button
                        key={`close-button-${index}`}
                        className="ml-2 dark:bg-[#3F3F46] bg-[#e5e7eb]"
                        size="sm"
                        isIconOnly
                        variant="bordered"
                        onClick={() => {
                          if (editSecondaryIndex === index) {
                            setEditSecondaryIndex(-1);
                          }
                          handleItemClose(item, setSecondary);
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-12 m-auto">
              <Button
                className="text-base"
                onClick={() =>
                  bigTextWordsRemaining >= minWords
                    ? generateFeatures(true, false)
                    : setGenFeaturesClicked(true)
                }
                isLoading={featureGenerating === "primary"}
              >
                Auto-Generate Primary Features <Stars width={24} height={24} />
              </Button>
              {bigTextWordsRemaining < minWords && gen_features_clicked && (
                <div className="text-red-500 mt-2">
                  Please enter at least {minWords} words to enable feature generation.
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full justify-center py-10 gap-20">
            <Button
              size="lg"
              className="text-base"
              color="default"
              onClick={() => {
                setInputValidation({
                  technologyArea: false,
                  inventionType: false,
                  titleMatter: false,
                  bigTextBox: false,
                  primary: false,
                  primaryTextLeft: false,
                  secondaryTextLeft: false,
                  email: false,
                });
                setTechnologyArea("");
                setInventionType("");
                setTitleMatter("");
                setBigTextBox("");
                setPrimaryInput("");
                setSecondaryInput("");
                setPrimary([]);
                setPrimaryExt([]);
                setSecondary([]);
                setIsFormValid(false);
                setFeatureGenerating("");
                setSelectedInventionId("");
              }}
            >
              Clear Entry Fields
            </Button>
            <Button
              size="lg"
              className="text-base"
              endContent={<IoIosSave size={20} />}
              onClick={() => saveInvention()}
              isLoading={isSavingInvention}
            >
              Save Invention
            </Button>
            {isSaveModalOpen && (
              <LoadInventionModal
                isOpen={isSaveModalOpen}
                inventionTitle={modalInput?.input.titleMatter}
                onClose={() => {
                  setIsSavingInvention(false);
                  setIsSaveModalOpen(false);
                }}
                onConfirm={handleConfirmOverwrite}
              />
            )}

            {inventions && (
              <div className="w-[20%]">
                <LoadInvention
                  inventions={inventions}
                  onInventionSelect={setSelectedInventionId}
                  selectedInventionId={selectedInventionId}
                />
              </div>
            )}
            <Button
              className="text-base max-w-[20%] dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
              size="lg"
              onClick={() => {
                const isTechnologyAreaValid = technologyArea !== null && technologyArea !== "";
                const isInventionTypeValid = inventionType !== null && inventionType !== "";
                const isTitleMatterValid = titleMatter !== "";
                const isEmailValid = validateEmail(email);
                const isBigTextBoxValid = validateBigText(bigTextBox);
                const isPrimaryValid = primary.length > 0;
                const isPrimaryTextLeft = primaryInput.trim() !== "";
                const isSecondaryTextLeft = secondaryInput.trim() !== "";

                setInputValidation({
                  technologyArea: !isTechnologyAreaValid,
                  inventionType: !isInventionTypeValid,
                  titleMatter: !isTitleMatterValid,
                  bigTextBox: !isBigTextBoxValid,
                  primary: !isPrimaryValid,
                  primaryTextLeft: isPrimaryTextLeft,
                  secondaryTextLeft: isSecondaryTextLeft,
                  email: !isEmailValid,
                });
                if (isEmailValid) {
                  if (
                    isTechnologyAreaValid &&
                    isInventionTypeValid &&
                    isTitleMatterValid &&
                    isBigTextBoxValid &&
                    isPrimaryValid &&
                    !isPrimaryTextLeft &&
                    !isSecondaryTextLeft
                  ) {
                    setCurrentPage(currentPage + 1);
                  }
                } else {
                  setIsLoginModalOpen(true);
                }
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {currentPage === 2 && (
        <div className="mx-auto max-w-[80%]">
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold w-3/4">Primary Features</h2>
              <div className="text-sm font-semibold w-1/4 text-center">
                Select the feature(s) most relevant to novelty
              </div>
            </div>
            {primaryExt.map((item, index) => (
              <div key={index} className="flex justify-between items-center">
                <div className="w-3/4">
                  <Textarea readOnly value={item[0]} minRows={1} />
                </div>
                <div className="flex gap-2 w-1/4 justify-center">
                  <Button
                    onClick={() => handleIsUniqueButtonClick(index)}
                    className={item[1] ? "active dark:bg-[#d1d5db] bg-[#1e293b]" : ""}
                  >
                    <Star
                      width={18}
                      height={18}
                      style={{ fill: item[1] ? "#c99a0c" : "#9ca3af" }}
                    />
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <div className="flex w-full justify-left py-10 gap-20 ml-5">
            <Button size="lg" className="text-base" onClick={() => setCurrentPage(currentPage - 1)}>
              Previous
            </Button>
            <Button
              size="lg"
              className="text-base"
              endContent={<IoIosSave size={20} />}
              onClick={() => saveInvention()}
              isLoading={isSavingInvention}
            >
              Save Invention
            </Button>
            {inventions && (
              <div className="w-[25%]">
                <LoadInvention
                  inventions={inventions}
                  onInventionSelect={setSelectedInventionId}
                  selectedInventionId={selectedInventionId}
                />
              </div>
            )}
            <Button
              size="lg"
              className="text-base dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
              isLoading={isResultsAPILoading}
              onClick={async () => {
                const isTechnologyAreaValid = technologyArea !== null && technologyArea !== "";
                const isInventionTypeValid = inventionType !== null && inventionType !== "";
                const isTitleMatterValid = titleMatter !== "";
                const isEmailValid = validateEmail(email);
                const isBigTextBoxValid = validateBigText(bigTextBox);
                const isPrimaryValid = primary.length > 0;
                const isPrimaryTextLeft = primaryInput.trim() !== "";
                const isSecondaryTextLeft = secondaryInput.trim() !== "";

                setInputValidation({
                  technologyArea: !isTechnologyAreaValid,
                  inventionType: !isInventionTypeValid,
                  titleMatter: !isTitleMatterValid,
                  bigTextBox: !isBigTextBoxValid,
                  primary: !isPrimaryValid,
                  primaryTextLeft: isPrimaryTextLeft,
                  secondaryTextLeft: isSecondaryTextLeft,
                  email: !isEmailValid,
                });
                if (isEmailValid) {
                  if (
                    isTechnologyAreaValid &&
                    isInventionTypeValid &&
                    isTitleMatterValid &&
                    isBigTextBoxValid &&
                    isPrimaryValid &&
                    !isPrimaryTextLeft &&
                    !isSecondaryTextLeft
                  ) {
                    setIsResultsAPILoading(true);
                    setCurrentPage(currentPage + 1);
                    setIsResultsAPILoading(false);
                  }
                } else {
                  setIsLoginModalOpen(true);
                }
              }}
            >
              Checkout
            </Button>
          </div>
        </div>
      )}
      {currentPage === 3 && (
        <div>
          <StripeCheckoutForm onSuccess={onSuccess} />
        </div>
      )}
    </section>
  );
}
