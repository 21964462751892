import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from "@nextui-org/react";
import { updateUser } from "../services/userService";
import { DocumentData } from "firebase/firestore";

type TermsOfServiceProps = {
  isOpen: any;
  onClose: () => void;
  userDoc: DocumentData | undefined;
};

export default function TermsOfService({ isOpen, onClose, userDoc }: TermsOfServiceProps) {
  const handleTosAccept = async () => {
    await updateUser({ id: userDoc?.id, data: { acceptedTOS: true } });
    onClose();
  };
  return (
    <Modal
      size="5xl"
      scrollBehavior="inside"
      classNames={{ closeButton: "hidden" }}
      backdrop="blur"
      isOpen={isOpen}
      onClose={onClose}
      isDismissable={false}
      isKeyboardDismissDisabled={true}
    >
      <ModalContent>
        <>
          <ModalHeader className="flex flex-col gap-1">
            <h1 className="font-bold text-3xl">IntelliPat, Inc. Beta Software Terms of Service</h1>
          </ModalHeader>
          <ModalBody>
            <h2 className="font-bold text-xl">1. Introduction</h2>
            <p>
              Thank you for participating in the beta testing of IntelliPat, Inc.'s software ("Beta
              Software"). By accessing or using our Beta Software, you acknowledge that you have
              read, understood, and agree to be bound by these Terms of Service ("ToS").
            </p>
            <h2 className="font-bold text-xl">2. Beta Software Use</h2>
            <p>
              The Beta Software is provided for the purpose of testing and evaluation. You
              acknowledge that the Beta Software is a pre-release version and may not work correctly
              in one or more aspects or in the way a final version of the software will. Your use of
              the Beta Software is voluntary and does not entitle you to any compensation.
            </p>
            <p>
              The Beta Software is intended to assist a user in evaluating information provided by
              the user. By using the Beta Software, you acknowledge and agree that the Beta Software
              is not a substitute for professional legal advice, and any decisions regarding matters
              of legal import relating to information provided by the user should be made in
              consultation with a qualified attorney.
            </p>
            <p>
              IntelliPat, Inc. does not provide legal advice or opinions. The information provided
              by the Beta Software is for informational purposes only and should not be construed as
              legal advice. You agree that IntelliPat, Inc. shall not be liable for any reliance on
              information provided by the Beta Software or any decisions made based thereon.
            </p>
            <h2 className="font-bold text-xl">3. Intellectual Property</h2>
            <p>
              <span className="font-bold">Your Information:</span> You retain all rights to any
              data, information, or intellectual property you submit while using the Beta Software.
            </p>
            <p>
              <span className="font-bold">Beta Software:</span> All rights, title, and interest in
              and to the Beta Software, including any updates, improvements, and modifications,
              belong to IntelliPat, Inc. and its licensors.
            </p>
            <h2 className="font-bold text-xl">4. Disclaimers and Limitations of Liability</h2>
            <p>
              The Beta Software is provided "AS-IS" without warranties of any kind. IntelliPat, Inc.
              expressly disclaims any warranties, express or implied, including but not limited to,
              implied warranties of merchantability, fitness for a particular purpose, and
              non-infringement.
            </p>
            <p>
              IntelliPat, Inc. shall not be liable for any direct, indirect, incidental, special,
              consequential, or exemplary damages, including but not limited to damages for loss of
              profits, goodwill, use, data, or other intangible losses resulting from the use of or
              inability to use the Beta Software.
            </p>
            <p>
              The ordering of references/information, as well as any assessments, metrics, and/or
              information relating to a search or any other engagement or activity associated with
              the Beta Software, serves solely to assist the user in understanding information
              presented by the Beta Software and is not a representation by IntelliPat, Inc.
              relating to any information provided by IntelliPat, Inc. or the user.
            </p>
            <p>
              While IntelliPat, Inc. strives to provide accurate and up-to-date information, we
              cannot guarantee the accuracy, completeness, or reliability of the results generated
              by the Beta Software. You acknowledge that patentability determinations are subject to
              various factors and uncertainties, and IntelliPat, Inc. makes no representations or
              warranties regarding the accuracy or reliability of the Beta Software.
            </p>
            <h2 className="font-bold text-xl">5. Indemnification</h2>
            <p>
              You agree to indemnify and hold harmless IntelliPat, Inc., its officers, directors,
              employees, and agents from any claim or demand, including reasonable attorneys' fees,
              made by any third party due to or arising out of your violation of these ToS.
            </p>
            <h2 className="font-bold text-xl">6. Modifications</h2>
            <p>
              IntelliPat, Inc. reserves the right to modify these ToS at any time. You will be
              notified of any significant changes to the ToS and your continued use of the Beta
              Software after such changes will constitute your consent to the modified ToS.
            </p>
            <p>
              IntelliPat, Inc. reserves the right to modify or discontinue, temporarily or
              permanently, the Beta Software or any features or portions thereof without prior
              notice. Intellipat, Inc. shall not be liable to you or to any third party for any
              modification, suspension, or discontinuance of the Beta Software.
            </p>
            <h2 className="font-bold text-xl">7. Termination</h2>
            <p>
              IntelliPat, Inc. may terminate or suspend access to the Beta Software immediately,
              without prior notice or liability, for any reason, including, without limitation, your
              breach of the ToS.
            </p>
            <h2 className="font-bold text-xl">8. Governing Law</h2>
            <p>
              These ToS and your use of the Beta Software are governed by the laws of the State of
              Delaware.
            </p>
            <h2 className="font-bold text-xl">9. Entire Agreement</h2>
            <p>
              These ToS constitute the entire agreement between you and IntelliPat, Inc. regarding
              your use of the Beta Software, superseding any prior agreements between you and
              IntelliPat, Inc. relating to such subject matter.
            </p>
            <h2 className="font-bold text-xl">10. Links to Third-Party Sites</h2>
            <p>
              The Beta Software may include hyperlinks to websites operated by parties other than
              IntelliPat, Inc. Such hyperlinks are provided for your reference only. We do not
              control such websites and are not responsible for their contents or the privacy or
              other practices of such websites.
            </p>
            <h2 className="font-bold text-xl">11. Contact Information</h2>
            <p>
              For any questions or concerns about these ToS or the Beta Software, please contact
              IntelliPat, Inc. at{" "}
              <a className="underline" href="mailto:info@intellipat.ai">
                info@intellipat.ai
              </a>
              .
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className="text-base max-w-[20%] dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
              onPress={handleTosAccept}
            >
              Accept
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
