import { Accordion, AccordionItem } from "@nextui-org/accordion";
import FeatureList from "../../../components/FeatureList";
import PriorArtTable from "../../../components/PriorArtTable";
import ReportHeader from "../../../components/ReportHeader";
import FeatureSynopsisTable from "../../../components/FeatureSynopsisTable";
import { getAllSearchesAndSubDocuments } from "../../../services/searchService";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "@nextui-org/react";

export default function ViewReportPage(props: { isAuthenticated: boolean }) {
  const navigate = useNavigate();

  useEffect(() => {
    props.isAuthenticated !== true && navigate("/");
  }, []);

  const [isPageLoading, setPageLoading] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [reportData, setReportData] = useState<any>({});

  const { id } = useParams();

  useEffect(() => {
    const fetchReport = async () => {
      setPageLoading(true);
      try {
        if (id) {
          const data = await getAllSearchesAndSubDocuments(id);
          const metadata = JSON.parse(data?.mainDocument?.data.metadata);
          setReportData(metadata);
        }
      } catch (error) {
        console.error("Failed to fetch report data:", error);
      } finally {
        setPageLoading(false);
      }
    };

    fetchReport();
  }, [id]);

  if (isPageLoading) {
    return (
      <div className="flex justify-center items-center mt-20">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <ReportHeader
        noveltyScore={reportData.noveltyScore}
        featureCoverage={reportData.featureCoverage}
        secondaryFeatureCoverage={reportData.secondaryFeatureCoverage}
        priorArtReferences={reportData.priorArtReferences}
      />

      <Accordion variant="splitted">
        <AccordionItem
          aria-label="Accordion 1"
          key="1"
          subtitle="Select to view primary and secondary features"
          title="Feature List"
        >
          <FeatureList
            primaryFeatures={reportData.primaryFeatures}
            secondaryFeatures={reportData.secondaryFeatures}
          />
        </AccordionItem>

        <AccordionItem
          aria-label="Accordion 2"
          key="3"
          subtitle="Select to view the prior art table"
          title="Prior Art Table"
        >
          <PriorArtTable priorArtTableData={reportData.priorArtTable} />
        </AccordionItem>

        <AccordionItem
          aria-label="Accordion 3"
          key="5"
          subtitle="Select to view the feature synopsis"
          title="Feature Synopsis"
        >
          <FeatureSynopsisTable
            primaryFeatureTable={reportData.primaryFeatureTable}
            secondaryFeatureTable={reportData.secondaryFeatureTable}
          />
        </AccordionItem>
      </Accordion>
    </>
  );
}
