import { useNavigate } from "react-router-dom";

import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, User } from "@nextui-org/react";

import { signOut } from "aws-amplify/auth";
import { UserAvatarTypes } from "../types/userAvatarTypes";
import { useEffect, useState } from "react";

const UserAvatar = (props: UserAvatarTypes) => {
  const navigate = useNavigate();

  const [userProps, setUserProps] = useState({ name: "", picture: "" });

  useEffect(() => {
    if (props.isAuthenticated && props.userIdentity) {
      setUserProps({
        name: props.userIdentity.name ? props.userIdentity.name : props.userIdentity.email,
        picture: props.userIdentity.picture ? props.userIdentity.picture : "",
      });
    }
  }, [props.isAuthenticated, props.userIdentity]);

  async function handleSignOut() {
    try {
      await signOut();
      props.updateAuthStatus(false);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  const dropdownItems = [
    <DropdownItem key="displayName">{userProps.name ? userProps.name : ""}</DropdownItem>,
    <DropdownItem key="search" onClick={() => navigate("/search")}>
      Search
    </DropdownItem>,
    <DropdownItem key="reports" onClick={() => navigate("/search/searches")}>
      Reports
    </DropdownItem>,
    props.adminUser && (
      <DropdownItem key="generateCoupons" onClick={() => navigate("/generate-coupons")}>
        Generate Coupons
      </DropdownItem>
    ),
    <DropdownItem key="settings" onClick={() => navigate("/settings")}>
      Settings
    </DropdownItem>,
    <DropdownItem
      key="signOut"
      className="hover:!bg-[#e2e8f0] hover:!text-black !transition-all"
      onClick={handleSignOut}
    >
      Sign Out
    </DropdownItem>,
  ];

  return (
    <div className="flex items-center gap-4">
      <Dropdown placement="bottom-start">
        <DropdownTrigger>
          <User
            as="button"
            avatarProps={{
              isBordered: true,
              src: userProps.picture ? userProps.picture : "",
            }}
            className="transition-transform"
            name=""
            aria-label="User Avatar"
          />
        </DropdownTrigger>
        <DropdownMenu aria-label="User Actions" disabledKeys={["displayName"]}>
          {dropdownItems}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default UserAvatar;
