// import { User } from 'firebase/auth';
import { db } from "../lib/firebase-config";
import {
  query,
  where,
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
  DocumentReference,
  DocumentData,
  getDoc,
  CollectionReference,
  FieldPath,
  WhereFilterOp,
  Timestamp,
  QueryConstraint,
} from "firebase/firestore";

interface MainDocument {
  id: string;
  data: DocumentData;
}

interface SubcollectionDocument {
  id: string;
  data: DocumentData;
}

interface MainAndSubcollection {
  mainDocument: MainDocument | null;
  subcollectionDocuments: SubcollectionDocument[];
}

const lastLoginAt = (milliseconds: string | number | undefined): any => {
  if (milliseconds === undefined) return undefined;
  const ms = typeof milliseconds === "number" ? milliseconds : parseInt(milliseconds);
  return timestampFromMilliseconds(ms);
};

const lastSignInTime = (dateTimeString: string | undefined): any => {
  return dateTimeString !== undefined ? timestampFromString(dateTimeString) : undefined;
};
const getCollection = (collectionName: string) => collection(db, collectionName);
export const getCollectionByRef = (
  docRef: DocumentReference<DocumentData>,
  collectionName: string
) => collection(docRef, collectionName);
const getCollectionByRefAndId = (
  docRef: CollectionReference<DocumentData, DocumentData>,
  collectionName: string,
  documentId: string
) => collection(docRef, collectionName, documentId);

export const getServerTimestampDB = () => serverTimestamp();

export const profileUser = async (currentAuthUser: any) => {
  const collectionName = "users";
  if (currentAuthUser) {
    let userData = await getDocumentById(collectionName, "auth_id", "==", currentAuthUser.sub);
    userData = {
      ...userData,
      // last_login: lastSignInTime(currentAuthUser.metadata.lastSignInTime)
    };
    return userData;
  }
  return undefined;
};

export const timestampFromMilliseconds = (milliseconds: number) =>
  Timestamp.fromMillis(milliseconds);

export const timestampFromString = (dateTimeString: string) =>
  Timestamp.fromDate(new Date(dateTimeString));

export const getAllData = async (collectionName: string) => {
  const q = query(getCollection(collectionName));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getAllDataByUserId = async (collectionName: string, userID: string) => {
  const q = query(getCollection(collectionName), where("user_id", "==", userID));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getAllDataByUserEmail = async (collectionName: string, email: string) => {
  const q = query(getCollection("users"), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  const getAllAuthId: any = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const uniqueAuthIds = Array.from(new Set(getAllAuthId.map((doc) => doc.auth_id)));
  let table_data: any = [];
  for (const getAuthId of uniqueAuthIds) {
    const q2 = query(getCollection(collectionName), where("user_id", "==", getAuthId));
    const querySnapshot2 = await getDocs(q2);
    table_data.push(...querySnapshot2.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  }
  return table_data;
};

export const getAllDocumentsFromMainAndSubcollection = async (
  mainCollectionName: string,
  fieldName: string,
  documentId: string,
  subcollectionName: string,
  constraints?: QueryConstraint[]
): Promise<MainAndSubcollection | undefined> => {
  try {
    const mainDocumentRef = collection(db, mainCollectionName);
    const mainDocumentQuery = query(mainDocumentRef, where(fieldName, "==", documentId));
    const mainDocumentSnapshot = await getDocs(mainDocumentQuery);

    const mainDocument: MainDocument | null = mainDocumentSnapshot.docs.length
      ? { id: mainDocumentSnapshot.docs[0].id, data: mainDocumentSnapshot.docs[0].data() }
      : null;

    if (mainDocument === null) {
      return undefined;
    }

    const subcollectionRef = getCollectionByRefAndId(
      mainDocumentRef,
      mainDocument.id,
      subcollectionName
    );
    const subcollectionQuery = constraints
      ? query(subcollectionRef, ...constraints)
      : query(subcollectionRef);
    const subcollectionSnapshot = await getDocs(subcollectionQuery);

    const subcollectionDocuments: SubcollectionDocument[] = subcollectionSnapshot.docs.map(
      (doc) => ({
        id: doc.id,
        data: doc.data(),
      })
    );

    return { mainDocument, subcollectionDocuments };
  } catch (error) {
    console.error("Error getting documents:", error);
    throw error;
  }
};
export const getAllAdminUsers = async (collectionName: string): Promise<string[]> => {
  try {
    const q = query(getCollection(collectionName));
    const querySnapshot = await getDocs(q);
    const adminEmails = querySnapshot.docs
      .filter((doc) => doc.data().email.endsWith("@pangeon.com") || doc.data().is_admin === true)
      .map((doc) => doc.data().email);
    return adminEmails;
  } catch (error) {
    console.error("Error fetching admin user:", error);
  }
};

export const getDocumentById = async (
  collectionName: string,
  field: string | FieldPath,
  opStr: WhereFilterOp,
  value: any
): Promise<DocumentData | undefined> => {
  const q = query(getCollection(collectionName), where(field, opStr, value));
  const docSnapshot = await getDocs(q);
  if (docSnapshot.empty) {
    return undefined;
  } else {
    if (docSnapshot.docs.length === 0) {
      return undefined;
    }
    const doc = docSnapshot.docs[0];
    if (doc.exists()) {
      return { id: doc.id, ...doc.data() };
    } else {
      return undefined;
    }
  }
};

export const getDocSnapshot = async (
  docRef: DocumentReference<DocumentData>
): Promise<DocumentData | undefined> => {
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    return { id: docSnapshot.id, ...docSnapshot.data() };
  } else {
    return undefined;
  }
};

export const getDocRef = (collectionName: string, documentId: string) => {
  const documentRef = doc(db, collectionName, documentId);
  return documentRef;
};

export const getDocRefByRef = (collectionRef: CollectionReference, documentId: string) => {
  const documentRef = doc(collectionRef, documentId);
  return documentRef;
};

export const addDocument = async (
  collectionName: string,
  data: any,
  docRef: DocumentReference<DocumentData> | undefined = undefined
) => {
  let referenceCollection: CollectionReference<DocumentData, DocumentData>;
  if (docRef !== undefined) {
    referenceCollection = getCollectionByRef(docRef, collectionName);
  } else {
    referenceCollection = getCollection(collectionName);
  }
  return await addDoc(referenceCollection, {
    ...data,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    is_activated: true,
  });
};

export const updateDocument = async (collectionName: string, documentId: string, data: any) => {
  const referenceCollection = getCollection(collectionName);
  const documentRef = doc(referenceCollection, documentId);
  await updateDoc(documentRef, data);
};

export const updateDocumentRef = async (docRef: DocumentReference<DocumentData>, data: any) => {
  await updateDoc(docRef, data);
};

export const deleteDocument = async (collectionName: string, documentId: string) => {
  const reference = doc(getCollection(collectionName), documentId);
  await deleteDoc(reference);
};
