import { Divider } from "@nextui-org/react";
import React from "react";
import CopyToClipboard from "./CopyToClipboard";
import { ReportHeaderProps } from "../types/reportTypes";

const ReportHeader: React.FC<ReportHeaderProps> = ({
  noveltyScore,
  featureCoverage,
  secondaryFeatureCoverage = [],
  priorArtReferences,
}) => {
  const coverageToFeatureNovelty = (coverage: number[] = []): number[] => {
    return coverage.map((num) => 100 - num);
  };
  const formatCoverage = (coverage?: number[]): string => {
    const featureNovelty = coverageToFeatureNovelty(coverage);
    return featureNovelty.join(", ");
  };

  return (
    <header className="flex flex-col items-center gap-4 mb-10">
      <div className="flex items-center gap-4">
        <h1 className="text-4xl font-bold">intelliPat NOVELTY REPORT</h1>
        <CopyToClipboard patentNumbers={priorArtReferences} />
      </div>
      <Divider orientation="horizontal" />
      <section className="flex items-center gap-6 h-10">
        <h2 className="text-2xl">Novelty Score: {noveltyScore}</h2>
        <Divider orientation="vertical" />
        <h2 className="text-2xl">Feature Novelty: {formatCoverage(featureCoverage)}</h2>

        {secondaryFeatureCoverage.length > 0 && (
          <>
            <Divider orientation="vertical" />
            <h2 className="text-2xl">
              Secondary Feature Novelty: {formatCoverage(secondaryFeatureCoverage)}
            </h2>
          </>
        )}
      </section>
      <Divider orientation="horizontal" />
    </header>
  );
};

export default ReportHeader;
