import { useAsyncList } from "@react-stately/data";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllInputSearch } from "../services/inputSearchService";
import DownloadJsonButton from "./DownloadJsonButton2";
import { IoMdClipboard } from "react-icons/io";
import {
  Button,
  Chip,
  Progress,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  getKeyValue,
} from "@nextui-org/react";

interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

interface Item {
  id: string;
  created_at: Timestamp;
  status?: string;
  metadata: string;
  results: {
    metadata: string;
  };
  technology_area: string;
  invention_type: string;
  titleMatter: string;
}

interface AuthUser {
  email: string;
}

const formatDate = (datetime: Timestamp) => {
  const date = new Date(datetime.seconds * 1000);
  const month = format(date, "MMM");
  const fullMonth = format(date, "MMMM");
  const restOfTheDate = format(date, "dd, yyyy HH:mm:ss");
  return fullMonth.length > 3 ? `${month}. ` + restOfTheDate : `${month} ` + restOfTheDate;
};

export default function SearchesResultsTable({ currentAuthUser }: { currentAuthUser: AuthUser }) {
  const [isLoading, setIsLoading] = useState(true);

  const list = useAsyncList({
    async load() {
      setIsLoading(true);
      try {
        const inputSearchTemp: Item[] = await getAllInputSearch(currentAuthUser);
        const inputSearch: Item[] = inputSearchTemp.map((input) => ({
          ...input,
          ...JSON.parse(input?.metadata || "{}"),
        }));

        const initialSortDescriptor = {
          column: "created_at",
          direction: "descending",
        };

        inputSearch.sort((a, b) => {
          const first = a.created_at.seconds;
          const second = b.created_at.seconds;
          let cmp = first < second ? -1 : 1;

          if (initialSortDescriptor.direction === "descending") {
            cmp *= -1;
          }
          return cmp;
        });

        return {
          items: inputSearch,
        };
      } catch (error) {
        console.error("Error loading data:", error);
        return { items: [] };
      } finally {
        setIsLoading(false);
      }
    },

    async sort({ items, sortDescriptor }) {
      return {
        items: items.sort((a, b) => {
          let cmp = 0;

          if (sortDescriptor.column === "created_at") {
            const first = a.created_at.seconds;
            const second = b.created_at.seconds;
            cmp = first - second;
          } else {
            const columnKey = sortDescriptor.column as keyof Item;
            const first = (a[columnKey] || "").toString();
            const second = (b[columnKey] || "").toString();
            cmp = first.localeCompare(second);
          }

          if (sortDescriptor.direction === "descending") {
            cmp = -1;
          }

          return cmp;
        }),
      };
    },
  });

  const copyIdToClipboard = async (id: string) => {
    try {
      await navigator.clipboard.writeText(id);
    } catch (err) {
      console.error("Failed to copy Id:", err);
    }
  };

  const navigate = useNavigate();
  function handleSearchAgain(item: Item) {
    return () => navigate("/search", { state: { ...item } });
  }

  const renderTableColumn = (item: Item, columnKey: keyof Item): React.ReactNode => {
    switch (columnKey) {
      case "created_at":
        return <Chip>{formatDate(item.created_at)}</Chip>;

      case "id":
        return (
          <Tooltip showArrow={true} content={item.id} placement="top-start" offset={-7}>
            <Button variant="light" size="sm" onPress={() => copyIdToClipboard(item.id)}>
              <IoMdClipboard className="text-lg" />
            </Button>
          </Tooltip>
        );

      case "status":
        return (
          <div className="flex">
            <Chip className="bg-default">
              {item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1) : "-"}
            </Chip>
          </div>
        );

      case "results":
        return (
          <div className="flex">
            {item.status === "done" && (
              <Button className="mr-2" size="sm" onClick={() => navigate(`${item.id}`)}>
                View
              </Button>
            )}
            <DownloadJsonButton
              item={item}
              fileName={`intellipat_report-${item.titleMatter}.pdf`}
            />
          </div>
        );

      default:
        return getKeyValue(item, columnKey) || "-";
    }
  };
  const getGradientClass = (value: number) => {
    if (value <= 33) {
      return "bg-gradient-to-r from-[#EA4228] to-[#EA4228]";
    } else if (value <= 66) {
      return "bg-gradient-to-r from-[#F5CD19] to-[#F5CD19]";
    } else {
      return "bg-gradient-to-r from-[#5BE12C] to-[#5BE12C]";
    }
  };
  const filteredItems = list.items.filter((item) => item.status);

  return (
    <Table
      aria-label="Example table with client side sorting"
      sortDescriptor={list.sortDescriptor}
      onSortChange={list.sort}
      style={{ maxHeight: "600px", overflowY: "auto" }}
      selectionMode="single"
    >
      <TableHeader>
        <TableColumn className="text-md text-light font-bold" key="created_at" allowsSorting>
          Created At
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="id" allowsSorting>
          Job ID
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="titleMatter" allowsSorting>
          Title/Matter Number
        </TableColumn>
        {/* <TableColumn key="invention_type" allowsSorting>
          Invention Type
        </TableColumn> */}
        {/* <TableColumn key="technology_area" allowsSorting>
          Technology Area
        </TableColumn> */}
        <TableColumn className="text-md text-light font-bold" key="status" allowsSorting>
          Process Status
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="noveltyScore">
          Novelty Score
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="results">
          Results
        </TableColumn>
        <TableColumn className="text-md text-light font-bold" key="searchAgain">
          Search again
        </TableColumn>
      </TableHeader>
      <TableBody
        items={filteredItems}
        isLoading={isLoading}
        loadingContent={<Spinner label="Loading..." />}
      >
        {(item) => (
          <TableRow key={item.id}>
            {(columnKey) => {
              if (columnKey === "noveltyScore") {
                return (
                  <TableCell key="noveltyScore">
                    {Object.keys(item.results).length > 0 ? (
                      <div>
                        <Progress
                          size="lg"
                          radius="full"
                          classNames={{
                            track: "drop-shadow-md border border-default",
                            indicator: getGradientClass(
                              JSON.parse(item.results.metadata).noveltyScore
                            ),
                            label: "tracking-wider font-medium text-default-600",
                          }}
                          value={JSON.parse(item.results.metadata).noveltyScore}
                          formatOptions={{ style: "decimal" }}
                        />
                        <div className="flex items-center mt-[-25px] relative translate-x-[-10%]">
                          <span className="font-bold text-dark dark:bg-black bg-white rounded-full border-2 dark:border-white border-black flex justify-center w-[30px] h-[30px] pb-[1px] items-center">
                            {JSON.parse(item.results.metadata).noveltyScore}
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </TableCell>
                );
              }
              if (columnKey === "searchAgain") {
                return (
                  <TableCell key="searchAgain">
                    <Button onPress={handleSearchAgain(item)}>Search Again</Button>
                  </TableCell>
                );
              }
              if (columnKey in item) {
                return (
                  <TableCell key={columnKey}>
                    {renderTableColumn(item, columnKey as keyof Item)}
                  </TableCell>
                );
              }
              return <TableCell key={columnKey}>-</TableCell>;
            }}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
