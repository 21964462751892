import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { getAllAdminUsers } from "./lib/firestoreOperations";

import Page from "./pages/page";
import Login from "./pages/auth/loginPage.tsx";
// import Dashboard from "./pages/dashboard/page";
import Search from "./pages/search/page";
import Searches from "./pages/search/searches/page";
import ViewReport from "./pages/search/viewReport/ViewReportPage";
import Settings from "./pages/settings/page";
import Coupons from "./pages/coupons/page";
import { getCustomTokenFromServer } from "./lib/firebase-config";
import RootLayout from "./pages/layout.tsx";
import Signup from "./pages/auth/signupPage.tsx";
// import Billing from "./pages/billing/page";
// import IDS from "./pages/ids/page";

const App: React.FC = () => {
  const [adminUser, setAdminUser] = useState<boolean>(false);
  const [userIdentity, setUserIdentity] = useState<any>();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async (): Promise<void> => {
    try {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        const { tokens } = await fetchAuthSession();
        let userIdentity = tokens?.idToken?.payload;
        setUserIdentity(userIdentity);
        updateAuthStatus(true);
        await getCustomTokenFromServer(userIdentity);
        if (userIdentity && userIdentity.email) {
          const adminEmails = await getAllAdminUsers("users");
          if (adminEmails.includes(userIdentity.email.toString())) {
            setAdminUser(true);
          }
        }
      }
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  function updateAuthStatus(authStatus: boolean | ((prevState: boolean) => boolean)) {
    setIsAuthenticated(authStatus);
  }

  return (
    <Router>
      <RootLayout
        isAuthenticated={isAuthenticated}
        updateAuthStatus={updateAuthStatus}
        userIdentity={userIdentity}
        adminUser={adminUser}
      >
        <Routes>
          {/* <Route path="*" element={<Page />} /> 404 PAGE */}
          <Route path="/" element={<Page />} />
          <Route
            path="/login"
            element={
              <Login
                updateAuthStatus={updateAuthStatus}
                isAuthenticated={isAuthenticated}
                getUser={getUser}
              />
            }
          />
          <Route
            path="/signup"
            element={
              <Signup
                updateAuthStatus={updateAuthStatus}
                isAuthenticated={isAuthenticated}
                getUser={getUser}
              />
            }
          />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/ids" element={<IDS />} /> */}
          <Route path="/search" element={<Search />} />
          <Route
            path="/search/searches"
            element={<Searches isAuthenticated={isAuthenticated} userIdentity={userIdentity} />}
          />
          <Route
            path="/search/searches/:id"
            element={<ViewReport isAuthenticated={isAuthenticated} />}
          />
          {/* <Route path="/billing" element={<Billing />} /> */}
          <Route path="/settings" element={<Settings isAuthenticated={isAuthenticated} />} />
          {adminUser ? (
            <Route
              path="/generate-coupons"
              element={<Coupons isAuthenticated={isAuthenticated} />}
            />
          ) : null}
        </Routes>
      </RootLayout>
    </Router>
  );
};

export default App;
