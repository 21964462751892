import { Button } from "@nextui-org/button";
import { useState } from "react";
import { IoMdCheckmark, IoMdClipboard } from "react-icons/io";

interface CopyToClipboardProps {
  patentNumbers: string[];
}

export default function CopyToClipboard({
  patentNumbers,
}: CopyToClipboardProps) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    const text = patentNumbers.join(", ");
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <Button
      className="flex items-center justify-center gap-2 font-semibold py-2 px-4 rounded ml-10"
      onPress={copyToClipboard}
    >
      <span>Copy All Patent Numbers</span>
      {copied ? (
        <IoMdCheckmark className="text-lg" />
      ) : (
        <IoMdClipboard className="text-lg" />
      )}
    </Button>
  );
}
