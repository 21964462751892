import Head from "next/head";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../lib/firebase-config";
import { Input } from "@nextui-org/react";
import { Button } from "@nextui-org/react";
import { getUserByAuthId, updateUser } from "../../services/userService";

export default function Settings(props: { isAuthenticated: boolean }) {
  const navigate = useNavigate();

  useEffect(() => {
    props.isAuthenticated !== true && navigate("/");
  }, []);

  const [user, setUser] = useState(null) as any;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await getUserData(user.uid);
      } else {
        navigate("/");
      }
    });
  }, []);

  async function getUserData(uid: string) {
    await getUserByAuthId(uid).then((doc) => {
      setUser(doc);
      setName(doc?.name || "");
      setEmail(doc?.email || "");
      setPhone(doc?.phone || "");
      setAddress(doc?.address || "");
      setCity(doc?.city || "");
      setState(doc?.state || "");
      setCountry(doc?.country || "");
      setZip(doc?.zip || "");
    });
  }

  async function handleUpdate() {
    if (user && user.id) {
      try {
        await updateUser({
          id: user.id,
          data: {
            phone,
            address,
            city,
            state,
            country,
            zip,
          },
        });
        await getUserData(user.auth_id);
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  }

  return (
    <>
      <Head>
        <title>Settings</title>
      </Head>
      {user && (
        <div className="flex flex-col h-full">
          <div className="flex flex-col justify-center items-center h-full">
            <div className="flex flex-col gap-2">
              <Input
                label="Name"
                placeholder="Name"
                value={name}
                disabled
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                label="Email"
                placeholder="Email"
                value={email}
                disabled
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                label="Phone"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <Input
                label="Address"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <Input
                label="City"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <Input
                label="State"
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
              <Input
                label="Country"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              <Input
                label="Zip"
                placeholder="Zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </div>
          </div>
          <div className="flex justify-center items-center h-full">
            <Button onClick={handleUpdate}>Update</Button>
          </div>
        </div>
      )}
    </>
  );
}
