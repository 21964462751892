export type SiteConfig = typeof siteConfig;

export const siteConfig = {
	name: "intelliPat",
	description: "intelliPat",
	navItems: [
		{
			label: "Dashboard",
			href: "/",
		},
		{
			label: "IDS",
			href: "/ids",
		},
		{
			label: "Search",
			href: "/search",
		},
		{
			label: "Searches",
			href: "/search/searches",
		},
		{
			label: "Billing",
			href: "/billing",
		},
		{
			label: "Settings",
			href: "/settings",
		}
	],
	navMenuItems: [
		{
			label: "Profile",
			href: "/profile",
		},
		{
			label: "Dashboard",
			href: "/dashboard",
		},
		{
			label: "Projects",
			href: "/projects",
		},
		{
			label: "Team",
			href: "/team",
		},
		{
			label: "Calendar",
			href: "/calendar",
		},
		{
			label: "Billing",
			href: "/billing",
		},
		{
			label: "Settings",
			href: "/settings",
		},
		{
			label: "Help & Feedback",
			href: "/help-feedback",
		},
		{
			label: "Logout",
			href: "/logout",
		},
	],
	links: {
		github: "https://github.com/nextui-org/nextui",
		twitter: "https://twitter.com/getnextui",
		docs: "https://nextui.org",
		discord: "https://discord.gg/9b6yyZKmH4",
		sponsor: "https://patreon.com/jrgarciadev"
	},
	apis: {
		v1: {
			googlePatentURL: import.meta.env.VITE_API_GOOGLE_PATENTS_URL,
			processIdsURL: import.meta.env.VITE_API_PROCESS_PATENTS_URL,
			searchURL: import.meta.env.VITE_API_SEARCH_URL,
		},
	},
	system: {
		appPort: "",
		debugMode: import.meta.env.VITE_DEBUG_MODE,
		queueUrl: import.meta.env.VITE_QUEUE_URL,
		awsAccessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
		awsSecretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
	}
};
